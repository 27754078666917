import scanAPI from '@/3d-vue-api/scanAPI';
import { OrderStatusEnum } from '@/domains/order/typescript/OrderInterface';
import { manufactureToDefaultValue, manufactureToNumber } from '@/domains/order/services/manufactureConverter';
import useScanStore from '@/domains/scan/store/scan.store';
import ManufactureTypeEnum from '@/domains/scan/typescript/enums/ManufactureTypeEnum';
import useAuthStore from '@/domains/authentication/store/auth.store';

import { soleList } from '@/domains/scan/services/configurationDatas';
import useSettingsStore from '@/domains/settings/store/settings.store';
import { defineStore, storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import type { OrderInterface } from '@/domains/order/typescript/OrderInterface';
import type SoleConfigurationInterface from '@/domains/scan/typescript/interfaces/SoleConfigurationInterface';
import type ScanPresetInterface from '@/domains/scan/typescript/interfaces/ScanPresetInterface';
import type OrderParametersFileInterface from '@/domains/order/typescript/OrderParametersFileInterface';

export default defineStore('order', () => {
    const settingsStore = useSettingsStore();
    const authStore = useAuthStore();
    const { selectedUserOffice } = storeToRefs(authStore);

    const orders = ref<Array<OrderInterface>>();

    const saveHash = ref();
    const lastSaveHash = ref();
    const isSaving = ref(false);

    const defaultOrder = computed<OrderInterface>(() => ({
        id: '',
        patient: {
            '@id': '',
            id: '',
            lastName: '',
            firstName: '',
        },
        consultant: `/office_users/${authStore.userProfile?.id}`,
        creationDate: new Date(),
        doctor: '',
        status: OrderStatusEnum.IN_CREATION,
        glued: false,
        lowCut: false,
        sanded: false,
        cuttingLength: 0,
        manufacture: 0,
        coveringMaterial: 0,
    }));

    function getDefaultOrder() {
        return defaultOrder.value;
    }
    const currentOrder = ref<OrderInterface>(defaultOrder.value);

    const defaultSoleConfiguration: SoleConfigurationInterface = {
        sole: '0',
        pattern: '0',
        heelPad: '0',
        metatarsalPad: '0',
        fullLengthPad: '0',
        isMaunalBend: false,
    };

    const soleConfiguration = ref<SoleConfigurationInterface>(defaultSoleConfiguration);

    const selectedOrders = ref<OrderInterface['id'][]>([]);

    const selectOrderById = (id: string | undefined) => {
        if (!id) {
            return;
        }
        selectedOrders.value.push(id);

        selectedOrders.value = [...new Set(selectedOrders.value)];
    };

    const isOrderSelected = (orderId: OrderInterface['id']): boolean => selectedOrders.value.includes(orderId);

    const unselectOrderById = (orderId: OrderInterface['id']) => {
        const index = selectedOrders.value.indexOf(orderId);
        if (index > -1) {
            selectedOrders.value.splice(index, 1);
        }
    };

    const initOrder = () => {
        let settingStoreManufacture = 0;
        if (selectedUserOffice.value?.manufacturingType === ManufactureTypeEnum.All) {
            settingStoreManufacture = settingsStore.manufacturePreferences.manufacture
                ? manufactureToNumber(settingsStore.manufacturePreferences.manufacture)
                : 0;
        } else {
            settingStoreManufacture = manufactureToDefaultValue(selectedUserOffice.value?.manufacturingType);
        }

        currentOrder.value = {
            ...defaultOrder.value,
            manufacture: settingStoreManufacture,
            comment: settingsStore.manufacturePreferences.comment,
            sanded: settingsStore.manufacturePreferences.isSanded,
            glued: settingsStore.manufacturePreferences.isGlued,
            lowCut: settingsStore.manufacturePreferences.manufacture === ManufactureTypeEnum.Print,
        };
    };

    const isMillingManufacture = computed<boolean>(
        () =>
            currentOrder.value.manufacture !== null &&
            currentOrder.value.manufacture === manufactureToNumber(ManufactureTypeEnum.Milling),
    );

    const isPrintManufacture = computed<boolean>(
        () =>
            currentOrder.value.manufacture !== null &&
            currentOrder.value.manufacture === manufactureToNumber(ManufactureTypeEnum.Print),
    );

    const compileOrderFilesToFormData = async (): Promise<FormData[]> => {
        const scanStore = useScanStore();
        const {
            soleParametersLeft,
            soleParametersRight,
            soleCorrectionsLeft,
            soleCorrectionsRight,
            soleMorphingLeft,
            soleMorphingRight,
            scanCorrectionElementList,
            scanHash,
            lastComputeScanHash,
        } = scanStore;
        const { getElementInfosById, screenshot } = scanAPI;

        const filesToPost: FormData[] = [];
        const elementInfos = scanCorrectionElementList.map((element) => {
            return {
                ...{
                    sceneParams: getElementInfosById(element.sceneId),
                    elementId: element.elementId,
                    isRightFoot: element.isRightFoot,
                    surfaceType: element.surfaceType,
                    thickness: element.thickness,
                },
            };
        });

        const paramsAndSceneInfosfile: OrderParametersFileInterface = {
            formInfos: {
                parameters: {
                    left: soleParametersLeft,
                    right: soleParametersRight,
                },
                corrections: {
                    left: soleCorrectionsLeft,
                    right: soleCorrectionsRight,
                },
                morphing: {
                    left: soleMorphingLeft,
                    right: soleMorphingRight,
                },
            },
            elementInfos,
            soleConfiguration: {
                ...soleConfiguration.value,
            },
            hash: scanHash ?? '',
            lastComputeHash: lastComputeScanHash ?? '',
        };
        const blobParams = new Blob([JSON.stringify(paramsAndSceneInfosfile)], { type: 'application/json' });
        const screenshotFile = await screenshot();

        const screenshotFormData = new FormData();
        screenshotFormData.append('screenshotFile', screenshotFile, 'screenshotFile.png');
        const orderParametersData = new FormData();
        orderParametersData.append('orderParametersFile', blobParams, 'orderParameters.json');
        const sceneFormData = new FormData();
        sceneFormData.append('sceneFile', scanAPI.export(), 'sceneFile.three');

        filesToPost.push(screenshotFormData);
        filesToPost.push(orderParametersData);
        filesToPost.push(sceneFormData);

        return filesToPost;
    };

    const loadingConfiguration = (soleConfigurationLoaded: SoleConfigurationInterface) => {
        soleConfiguration.value = soleConfigurationLoaded;
    };

    const applyPreset = (preset: ScanPresetInterface) => {
        soleConfiguration.value = {
            ...soleConfiguration.value,
            ...preset,
        };
    };

    function $reset() {
        console.info('reset order store');
        orders.value = undefined;
        currentOrder.value = defaultOrder.value;
        soleConfiguration.value = defaultSoleConfiguration;
        selectedOrders.value = [];
    }

    return {
        orders,
        selectedOrders,
        selectOrderById,
        isOrderSelected,
        unselectOrderById,
        currentOrder,
        initOrder,
        isMillingManufacture,
        isPrintManufacture,
        soleConfiguration,
        soleList,
        compileOrderFilesToFormData,
        getDefaultOrder,
        loadingConfiguration,
        applyPreset,

        saveHash,
        lastSaveHash,
        isSaving,

        defaultOrder,

        $reset,
    };
});
