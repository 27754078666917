import { handleHttpError } from './errorHandler';
import { checkTokenIsExpired } from '@/domains/authentication/services/authenticate';
import i18n from '@/translations';

class API {
    private apiUri: string;

    constructor() {
        this.apiUri = import.meta.env.VITE_API_URI;
    }

    public async getJson<T>(
        url: string,
        request?: RequestInit,
        headers?: Headers,
        options: { triggerNotification?: boolean } = { triggerNotification: true },
    ): Promise<T> {
        const requestInit = {
            headers,
            ...request,
            credentials: 'include',
        } as RequestInit;
        const resp = await fetch(`${this.apiUri}${url}`, requestInit);

        return API.responseBodyCheck(resp, options.triggerNotification);
    }

    public async postJson<T>(url: string, request?: RequestInit, headers?: Headers): Promise<T> {
        const requestInit = {
            headers,
            ...request,
            credentials: 'include',
            method: 'POST',
        } as RequestInit;

        const resp = await fetch(`${this.apiUri}${url}`, requestInit);

        return API.responseBodyCheck(resp);
    }

    public async postBlob(url: string, request?: RequestInit, headers?: Headers): Promise<Blob> {
        const requestInit = {
            headers,
            ...request,
            credentials: 'include',
            method: 'POST',
        } as RequestInit;

        const resp = await fetch(`${this.apiUri}${url}`, requestInit);

        return resp.blob();
    }

    public async putJson<T>(url: string, request?: RequestInit, headers?: Headers): Promise<T> {
        const requestInit = {
            headers,
            ...request,
            credentials: 'include',
            method: 'PUT',
        } as RequestInit;

        const resp = await fetch(`${this.apiUri}${url}`, requestInit);

        return API.responseBodyCheck(resp);
    }

    public async postFormData<T>(url: string, body: FormData): Promise<T> {
        const requestInit = {
            body,
            credentials: 'include',
            method: 'POST',
        } as RequestInit;

        const resp = await fetch(`${this.apiUri}${url}`, requestInit);

        return API.responseBodyCheck(resp);
    }

    public async patchJson<T>(url: string, request?: RequestInit, headers?: Headers): Promise<T> {
        const requestInit = {
            headers,
            ...request,
            credentials: 'include',
            method: 'PATCH',
        } as RequestInit;

        const resp = await fetch(`${this.apiUri}${url}`, requestInit);

        return API.responseBodyCheck(resp);
    }

    public async deleteJson(url: string, request?: RequestInit, headers?: Headers): Promise<void> {
        const requestInit = {
            headers,
            ...request,
            credentials: 'include',
            method: 'DELETE',
        } as RequestInit;

        const resp = await fetch(`${this.apiUri}${url}`, requestInit);

        return API.responseBodyCheck(resp, false);
    }

    private static async responseBodyCheck(resp: Response, triggerNotification = true): Promise<any> {
        const { t } = i18n.global;
        const textBody = await resp.text();
        if (!textBody.length) {
            return null;
        }
        const jsonBody = JSON.parse(textBody);
        checkTokenIsExpired(jsonBody, resp.status);

        if (!resp.ok) {
            const message = jsonBody.message ?? t('api.500');
            if (triggerNotification) {
                handleHttpError(resp.status, message, true);
            }
            throw new Error(message);
        }

        if (resp.status === 400) {
            if (triggerNotification) {
                handleHttpError(resp.status, 'Serveur error 400', true);
            }
            throw new Error('Serveur error 400');
        }

        return jsonBody;
    }
}

const Api = new API();

export default Api;
