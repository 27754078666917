<script setup lang="ts">
import getClassesFromColor from '@/domains/common/services/getBaseButtonColor';
import ButtonColorEnum from '@/domains/common/typescript/ButtonColorEnum';
import TooltipPositionEnum from '@/domains/common/typescript/TooltipPositionEnum';
import { computed, ref } from 'vue';

interface Props {
    icon?: Object;
    label: string;
    showLabel?: boolean;
    disabled?: boolean;
    defaultClasses?: boolean;
    color?: ButtonColorEnum;
    tooltipText?: string;
    tooltipPosition?: TooltipPositionEnum;
    type?: 'button' | 'submit' | 'reset';
    dataTest?: string;
}

defineEmits<{
    'click': [];
}>();

const button = ref<HTMLButtonElement>();
const isTooltipVisible = ref(false);

const props = withDefaults(defineProps<Props>(), {
    icon: undefined,
    showLabel: true,
    disabled: false,
    defaultClasses: true,
    color: ButtonColorEnum.None,
    tooltipPosition: TooltipPositionEnum.TOP,
    tooltipText: undefined,
    type: 'button',
    dataTest: undefined,
});

const colorClasses = computed(() => getClassesFromColor(props.color, props.disabled));
</script>

<template>
    <button
        ref="button"
        :type="type"
        :class="[
            defaultClasses && 'flex cursor-pointer items-center justify-center rounded-2xl font-semibold',
            disabled && 'hover:cursor-not-allowed',
            $attrs.class,
            colorClasses.background,
            colorClasses.text,
        ]"
        :disabled="props.disabled"
        :data-test="dataTest"
        @mouseenter="isTooltipVisible = true"
        @mouseleave="isTooltipVisible = false"
        @click="$emit('click')"
    >
        <component :is="props.icon" v-if="props.icon !== undefined" :aria-hidden="!props.showLabel" />
        <span :class="[props.icon && 'ml-2', !props.showLabel && 'sr-only']">{{ props.label }}</span>
        <slot />
    </button>
    <BaseTooltip
        v-if="tooltipText && button"
        :text="tooltipText"
        :parent="button"
        :visible="isTooltipVisible"
        :position="tooltipPosition"
    />
</template>
