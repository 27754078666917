import MainLoop from '@/3d-app/commons/mainLoop/MainLoop';
import eventBus from '@/3d-app/commons/EventBus';
import { Camera, PerspectiveCamera, Scene, WebGLRenderer, type OffscreenCanvas } from 'three';
import type IWorld from './IWorld';
import type { ContainerSize } from './IWorld';
import type TWorldParams from './TWorldParams';
import type TLoopInfo from '@/3d-app/commons/mainLoop/TLoopInfo';

/**
 * A world is the main class for init a 3d world
 * Inherit from this class to create your own world
 *
 * It contains a renderer, a scene, a camera and a mainLoop
 * The child class must be instanciated when the DOM is ready ( with a vue component at onMounted for example)
 *
 * It also contains a resize method to resize the canvas when the window is resized
 * The canvas is resized to the container size
 *
 * @TWorldParams : Is the constructor's argument to init the world
 */
class World implements IWorld {
    private static _worlds: IWorld[] = [];

    private static resizerInitialised = false;

    protected _renderer: WebGLRenderer;

    protected _scene: Scene;

    protected _camera: Camera;

    protected _container: HTMLElement | Window;

    protected _canvas: HTMLCanvasElement | OffscreenCanvas | undefined;

    protected _mainLoop: MainLoop;

    protected _params: TWorldParams;

    get canvas(): HTMLCanvasElement | OffscreenCanvas | undefined {
        return this._canvas;
    }

    get renderer(): WebGLRenderer {
        return this._renderer;
    }

    get scene(): Scene {
        return this._scene;
    }

    get camera(): Camera {
        return this._camera;
    }

    get mainLoop(): MainLoop {
        return this._mainLoop;
    }

    get worldParams(): TWorldParams {
        return this._params;
    }

    constructor(params: TWorldParams) {
        this._params = params;
        this._container = params.container;
        this._canvas = params.webGLParameters.canvas;
        try {
            this._renderer = new WebGLRenderer(params.webGLParameters);
            this._renderer.setSize(this.containerSize.width, this.containerSize.height);
        } catch (error) {
            console.error(error);
            eventBus.emit('webgl-context-error');
            // Initialize a fallback renderer to ensure this._renderer is always assigned
            this._renderer = new WebGLRenderer({ canvas: document.createElement('canvas') });
        }
        this._scene = new Scene();
        this._camera = new PerspectiveCamera();

        World._worlds.push(this);
        World.initResizer();

        this._mainLoop = new MainLoop(params.mainLoopParams);
        this._mainLoop.addCallback(this.render.bind(this));
        this._canvas?.addEventListener('webglcontextlost', this.onWebGLContextLost);
    }

    onWebGLContextLost = (event: Event) => {
        event.preventDefault();
        this.dispose();
        eventBus.emit('webgl-context-error');
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    render(loopInfo?: TLoopInfo) {
        this._renderer.render(this._scene, this._camera);
    }

    dispose() {
        this._canvas?.removeEventListener('webglcontextlost', this.onWebGLContextLost);
        this._renderer.dispose();
        this._renderer.render(this._scene, this._camera);
        this._mainLoop.dispose();
        World._worlds = World._worlds.filter((world: IWorld) => world !== this);
        this._renderer.forceContextLoss();
    }

    get containerSize(): ContainerSize {
        if (this._container instanceof Window) {
            return {
                width: this._container.innerWidth,
                height: this._container.innerHeight,
                aspect: this._container.innerWidth / this._container.innerHeight,
            };
        }
        if (this._container instanceof HTMLElement) {
            return {
                width: this._container.clientWidth,
                height: this._container.clientHeight,
                aspect: this._container.clientWidth / this._container.clientHeight,
            };
        }
        throw new Error('container is not HTMLElement or Window');
    }

    resize() {
        if (this.camera instanceof PerspectiveCamera) {
            const cam: PerspectiveCamera = this.camera;
            cam.aspect = this.containerSize.aspect;
            cam.updateProjectionMatrix();
        }

        this._renderer.setSize(this.containerSize.width, this.containerSize.height);

        this._renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

        this._renderer.render(this._scene, this._camera);
    }

    static initResizer() {
        if (World.resizerInitialised) {
            return;
        }
        World.resizerInitialised = true;
        window.addEventListener('resize', () => {
            World._worlds.forEach((world: IWorld) => world.resize());
        });
    }
}

export default World;
