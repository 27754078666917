<script setup lang="ts">
import { ref } from 'vue';
import { Switch } from '@headlessui/vue';

interface Props {
    modelValue: boolean;
    label?: string;
}

const props = defineProps<Props>();

const enabled = ref<boolean>(props.modelValue);

defineEmits<{
    'update:modelValue': [value: boolean];
}>();
</script>

<template>
    <Switch
        v-model="enabled"
        :class="[
            'relative inline-flex h-[32px] w-[62px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75',
            enabled ? 'bg-success-bg' : 'bg-disabled',
        ]"
        data-test="switch-input"
        @update:model-value="$emit('update:modelValue', $event)"
    >
        <span class="sr-only">{{ label }}</span>
        <span
            aria-hidden="true"
            :class="[
                'pointer-events-none inline-block h-[28px] w-[28px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out',
                enabled ? 'translate-x-[30px]' : 'translate-x-0',
            ]"
        />
    </Switch>
</template>
