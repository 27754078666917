<script setup lang="ts">
import IconCancel from '@/domains/common/components/icons/IconCancel.vue';
import { onClickOutside } from '@vueuse/core';
import { ref } from 'vue';

const emit = defineEmits<{
    close: [];
}>();

const modal = ref();

onClickOutside(modal, () => {
    emit('close');
});
</script>

<template>
    <Teleport to="body">
        <div class="fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center bg-white/60">
            <div ref="modal" :class="['modal-shadow w-5/6 rounded-3xl bg-white p-8', $attrs.class]">
                <div class="relative mb-4 flex w-full flex-row items-center justify-center text-lg font-bold">
                    <slot name="title" />
                    <BaseButton
                        class="absolute right-0"
                        :icon="IconCancel"
                        label=""
                        :show-label="false"
                        @click="$emit('close')"
                    />
                </div>
                <slot>
                    <!-- Modal Body -->
                </slot>
            </div>
        </div>
    </Teleport>
</template>

<style>
.modal-shadow {
    box-shadow: rgb(0 0 0 / 10%) 0 10px 50px;
}
</style>
