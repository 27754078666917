<script setup lang="ts">
import ColorEnum from '@/domains/common/typescript/ColorEnum';
import IconSearch from '@/domains/common/components/icons/IconSearch.vue';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/vue';
import { computed, ref, watch, watchEffect } from 'vue';
import type SelectOptionInterface from '@/domains/common/typescript/SelectOptionInterface';
import type BaseSelectValueType from '@/domains/common/typescript/BaseSelectValueType';

interface Props {
    mode?: 'text' | 'button' | 'mediumText';
    emptyValue?: string;
    options: SelectOptionInterface<string>[];
    modelValue: SelectOptionInterface<string>['value'] | SelectOptionInterface<string>['value'][] | null;
    color?: ColorEnum;
    disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    emptyValue: '',
    mode: 'button',
    color: ColorEnum.Primary,
    disabled: false,
});

const colorClass: Record<ColorEnum, string> = {
    primary: 'border-transparent',
    secondary: 'border-gray-400',
    primaryFilled: '',
};

const isMultiple = computed(() => Array.isArray(props.modelValue));

const getOptionValueFromProps = (): SelectOptionInterface<string> | SelectOptionInterface<string>[] | null => {
    if (Array.isArray(props.modelValue)) {
        return props.options.filter((option: SelectOptionInterface<string>) => {
            if (Array.isArray(props.modelValue)) {
                return props.modelValue.some((modelValueItem) => modelValueItem === option.value);
            }
            return false;
        });
    }
    return props.options.find((option: SelectOptionInterface<string>) => option.value === props.modelValue) ?? null;
};

const emit = defineEmits<{
    'update:modelValue': [value: BaseSelectValueType<string>];
    'input': [value: string];
}>();

const selectedValue = ref<SelectOptionInterface<string> | SelectOptionInterface<string>[] | null>(
    getOptionValueFromProps(),
);
const query = ref('');

const filteredOptions = computed<SelectOptionInterface<string>[]>(() => {
    if (query.value === '') {
        return props.options;
    }
    return props.options.filter((option) => option.label.toLowerCase().includes(query.value.toLowerCase()));
});

function getDisplayedValue(value: unknown): string {
    const optionValue: SelectOptionInterface<string> | SelectOptionInterface<string>[] = value as
        | SelectOptionInterface<string>
        | SelectOptionInterface<string>[];

    if (optionValue === null) {
        return '';
    }

    const matchingOption = props.options
        .filter((option: SelectOptionInterface<string>) => {
            if (optionValue === null) {
                return false;
            }
            if (Array.isArray(optionValue)) {
                return optionValue.some((optionValueItem) => optionValueItem.value === option.value);
            }
            return option.value === optionValue.value;
        })
        .map((option) => option.label);

    return matchingOption.length === 0 ? '' : matchingOption.toString();
}

function setQuery(value: string) {
    query.value = value;
    emit('input', value);
}

function onFocus(event: { target: HTMLInputElement }) {
    event.target.dispatchEvent(new KeyboardEvent('keydown', { 'key': 'ArrowDown' }));
}

watch(selectedValue, (value) => {
    if (value === null) {
        emit('update:modelValue', null);
        return;
    }
    if (Array.isArray(value)) {
        emit(
            'update:modelValue',
            value.map((item) => item.value),
        );
        return;
    }
    emit('update:modelValue', value.value);
});
watchEffect(() => {
    selectedValue.value = getOptionValueFromProps();
});
</script>

<template>
    <Combobox v-model="selectedValue" :multiple="isMultiple" as="div" :disabled="disabled" class="group relative">
        <div :class="['relative', colorClass[props.color], $attrs.class]"></div>
        <div :class="['relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left']">
            <ComboboxInput
                :class="[
                    'w-full rounded-2xl border border-gray-400 py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 focus-visible:outline-none',
                    disabled && 'cursor-not-allowed bg-gray-300',
                    !disabled && 'bg-white',
                ]"
                :display-value="getDisplayedValue"
                as="input"
                :placeholder="emptyValue"
                data-test="select-input"
                @change="setQuery($event.target.value)"
                @focus="onFocus"
            />
            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center pr-2" data-test="select-button">
                <IconSearch
                    :class="['h-5 w-5 text-gray-400', !disabled && 'group-hover:text-secondary']"
                    aria-hidden="true"
                />
            </ComboboxButton>
        </div>
        <transition
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <ComboboxOptions
                class="absolute left-0 right-0 z-10 mx-auto mt-1 max-h-60 w-full min-w-full overflow-auto rounded-xl bg-white p-1 shadow-lg ring-2 ring-border focus:outline-none sm:text-sm"
            >
                <ComboboxOption
                    v-for="option in filteredOptions"
                    :key="option.value"
                    v-slot="{ active, selected }"
                    :value="option"
                >
                    <li
                        data-test="option-autocomplete"
                        :class="[
                            'relative cursor-default select-none rounded-lg px-2 py-2 text-center text-gray-900',
                            selected && 'bg-shadow',
                            !selected && 'bg-white',
                        ]"
                    >
                        <span
                            :class="[
                                (active || selected) && 'font-semibold',
                                !active && !selected && 'font-normal',
                                'block truncate',
                            ]"
                            v-text="option.label"
                        />
                    </li>
                </ComboboxOption>
            </ComboboxOptions>
        </transition>
    </Combobox>
</template>

<style scoped>
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    margin-top: 8px;
    margin-bottom: 8px;
    box-shadow: inset 0 0 5px #edebdf;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb {
    background: #cbd5e1;
    border-radius: 5px;
}
</style>
