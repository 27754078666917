<script setup lang="ts">
import IconChevronLeft from './icons/IconChevronLeft.vue';
import IconChevronRight from './icons/IconChevronRight.vue';
import { computed, ref, toRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useOffsetPagination } from '@vueuse/core';
import type BaseSelectValueType from '@/domains/common/typescript/BaseSelectValueType';

const { t } = useI18n();

const selectOptions = [
    {
        value: '10',
        label: t('pagination.line_count_label', { count: '10' }),
    },
    {
        value: '20',
        label: t('pagination.line_count_label', { count: '20' }),
    },
    {
        value: '50',
        label: t('pagination.line_count_label', { count: '50' }),
    },
    {
        value: '100',
        label: t('pagination.line_count_label', { count: '100' }),
    },
];

interface Props {
    fetchFunction: ({ page, limit }: { page: number; limit: number }) => void | Promise<void>;
    total: number;
}
const props = withDefaults(defineProps<Props>(), {});

const page = ref(1);
const pageSize = ref(10);
const total = toRef(props, 'total');

function fetchData({ currentPage, currentPageSize }: { currentPage: number; currentPageSize: number }) {
    props.fetchFunction({ page: currentPage, limit: currentPageSize });
}

const { currentPage, pageCount, isFirstPage, isLastPage } = useOffsetPagination({
    total,
    page,
    pageSize,
    onPageChange: fetchData,
    onPageSizeChange: fetchData,
});

const pagesToDisplay = computed((): number[] => {
    const pages: number[] = [];

    if (pageCount.value <= 7) {
        for (let pageNb = 1; pageNb <= pageCount.value; pageNb++) {
            pages.push(pageNb);
        }

        return pages;
    }

    if (currentPage.value < 5) {
        for (let pageNb = 1; pageNb <= 5; pageNb++) {
            pages.push(pageNb);
        }
        pages.push(0);
        pages.push(pageCount.value);
        return pages;
    }

    const endOffset = pageCount.value - currentPage.value;

    if (endOffset < 4) {
        pages.push(1);
        pages.push(0);
        const startPage = pageCount.value - 4;
        for (let pageNb = startPage; pageNb <= pageCount.value; pageNb++) {
            pages.push(pageNb);
        }
        return pages;
    }

    pages.push(1);
    pages.push(0);
    const startPage = currentPage.value - 1;
    const endPage = currentPage.value + 1;
    for (let pageNb = startPage; pageNb <= endPage; pageNb++) {
        pages.push(pageNb);
    }
    pages.push(0);
    pages.push(pageCount.value);

    return pages;
});

function onLimitChange(limit: BaseSelectValueType<string>) {
    if (limit && typeof limit === 'string') {
        page.value = 1;
        pageSize.value = parseInt(limit, 10);
    }
}
</script>

<template>
    <div class="flex flex-row items-center justify-center py-4">
        <button :disabled="isFirstPage" @click="currentPage--">
            <IconChevronLeft :class="['mr-2', !isFirstPage && 'text-theme-black', isFirstPage && 'text-border']" />
        </button>
        <BaseButton
            v-for="(pageNb, index) in pagesToDisplay"
            :key="index"
            :class="[
                'mx-0.5 h-7 w-7 rounded text-sm font-bold',
                currentPage === pageNb && 'bg-theme-blue-500 text-white',
                'hover:bg-white hover:text-theme-green-500',
            ]"
            :default-classes="false"
            :disabled="!pageNb"
            :label="!!pageNb ? `${pageNb}` : '...'"
            @click="page = pageNb"
        />
        <button :disabled="isLastPage" @click="currentPage++">
            <IconChevronRight :class="['ml-2 mr-7', !isLastPage && 'text-theme-black', isLastPage && 'text-border']" />
        </button>
        <span class="mr-8 text-sm font-bold">
            {{ $t('pagination.show') }}
        </span>
        <BaseSelect
            :options="selectOptions"
            :model-value="pageSize.toString()"
            :mode="'mediumText'"
            class="w-30 h-8 rounded-2xl bg-white px-2"
            :display-above="true"
            @update:model-value="onLimitChange"
        />
    </div>
</template>
