<script setup lang="ts">
import IconFile from './icons/IconFile.vue';
import { ref } from 'vue';
import { useClipboard } from '@vueuse/core';

defineOptions({
    inheritAttrs: false,
});

export interface Props {
    id: string;
    name?: string;
    value: string;
}

const props = withDefaults(defineProps<Props>(), {
    name: '',
});

const shoudlDisplayMessage = ref(false);

async function copyToClipboard() {
    await useClipboard().copy(props.value);
    shoudlDisplayMessage.value = true;
}
</script>

<template>
    <div>
        <div :class="['flex items-center overflow-hidden rounded-xl border border-theme-grey-200 bg-background']">
            <input
                :id="props.id"
                type="text"
                :class="['pointer-events-auto  px-2 py-2 focus-visible:outline-none']"
                :name="props.id ?? props.name"
                disabled
                :value="value"
                rows="4"
            />
            <BaseButton
                :icon="IconFile"
                label=""
                :show-label="false"
                class="flex h-full items-center px-4 text-gray-400"
                :class="['items-center']"
                :default-classes="false"
                @click="copyToClipboard"
            />
        </div>
        <div v-show="shoudlDisplayMessage" class="font-semibold text-theme-green-500">
            Valeur copiée dans le presse-papier
        </div>
    </div>
</template>
