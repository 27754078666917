<template>
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.13603 13.864C1.52656 14.2545 2.15972 14.2545 2.55025 13.864L7.5 8.9142L12.4498 13.864C12.8403 14.2545 13.4735 14.2545 13.864 13.864C14.2545 13.4734 14.2545 12.8403 13.864 12.4498L8.91421 7.49999L13.864 2.55025C14.2545 2.15972 14.2545 1.52656 13.864 1.13603C13.4734 0.745508 12.8403 0.745509 12.4497 1.13603L7.5 6.08577L2.55028 1.13605C2.15975 0.745526 1.52659 0.745526 1.13606 1.13605C0.745538 1.52657 0.745538 2.15974 1.13606 2.55026L6.08579 7.49999L1.13603 12.4497C0.745509 12.8403 0.745509 13.4734 1.13603 13.864Z"
            fill="currentColor"
        />
    </svg>
</template>
