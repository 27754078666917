import ScanController from '@/3d-app/scan/ScanController';
import MeshUtils from '@/3d-app/commons/MeshUtils';
import Tool from '@/3d-app/commons/Tool';
import { ArrowHelper, GridHelper, Object3D, Raycaster, Vector3 } from 'three';
import { lerp } from 'three/src/math/MathUtils';

/**
 * Controller used for morphing
 */
class MorphingTool extends Tool {
    private static readonly NB_CELL: number = 19;
    private static readonly GAP_X: number = 6;
    private static readonly GAP_Y: number = 2;

    private _isActive: boolean = false;

    private _debugObjects: Array<Object3D>;

    private _raycaster: Raycaster;

    private _keyboardEventListener = (event: KeyboardEvent) => this.onKeyEvent(event);

    constructor() {
        super();
        this._raycaster = new Raycaster();
        this._debugObjects = [];
    }

    public get active(): boolean {
        return this._isActive;
    }

    public set active(isActive: boolean) {
        if (this._isActive === isActive) {
            return;
        }
        this._isActive = isActive;
        if (this._isActive) {
            window.addEventListener('keydown', this._keyboardEventListener);
        } else {
            window.removeEventListener('keydown', this._keyboardEventListener);
        }
    }

    /**
     * Compute the morphing distances for given foot
     * @param isRightFoot true for right foot, false for left
     * @param showDebug true to create debug shapes
     * @returns all the morphing distances in an array of array
     */
    getMorphingOnFoot(isRightFoot: boolean, showDebug: boolean = false): (number | null)[][] {
        if (!ScanController.instance) {
            throw new Error('ScanController not ready!');
        }

        const { scene } = ScanController.instance;

        this.dispose();

        const template = ScanController.instance.getTemplateMesh(isRightFoot);
        const templatePosition = new Vector3();
        template.mesh.getWorldPosition(templatePosition);
        const templateScale = template.raycastMesh.scale;
        const { NB_CELL, GAP_X, GAP_Y } = MorphingTool;
        const gapX = (templateScale.x / (NB_CELL - GAP_X)) * GAP_X;
        const gapY = (templateScale.y / (NB_CELL - GAP_Y)) * GAP_Y;
        const gridScale = new Vector3(templateScale.x + gapX, templateScale.y + gapY, 1);

        // Raycast lines
        const halfScale = gridScale.clone().multiplyScalar(0.5);
        const startPos = templatePosition.clone().sub(halfScale);
        const endPos = templatePosition.clone().add(halfScale);
        const morphingGrid = [];

        const zOffset = -1; // 1 meter below ground to be sure we catch all negative intersections
        for (let y = 0; y <= NB_CELL; y++) {
            const row = [];
            for (let x = 0; x <= NB_CELL; x++) {
                const origin = new Vector3(
                    lerp(startPos.x, endPos.x, x / NB_CELL),
                    lerp(startPos.y, endPos.y, y / NB_CELL),
                    zOffset,
                );
                const distance = this.raycastOnFoot(isRightFoot, origin, showDebug);
                row.push(distance);
            }
            morphingGrid.push(row);
        }

        if (showDebug) {
            // Show a grid for rays origins
            const gridHelper = new GridHelper(1, NB_CELL, '#aa0000', '#8888cc');
            gridHelper.rotateX(Math.PI / 2);
            gridHelper.position.copy(templatePosition);
            gridHelper.scale.x = gridScale.x;
            gridHelper.scale.z = gridScale.y;

            this._debugObjects.push(gridHelper);

            this._debugObjects.forEach((mesh) => scene.add(mesh));
        }

        return morphingGrid;
    }

    raycastOnFoot(isRightFoot: boolean, origin: Vector3, showDebug: boolean = false): number | null {
        this._raycaster.set(origin, new Vector3(0, 0, 1));

        const raycastDest = ScanController.instance?.getScanMesh(isRightFoot)?.mesh;
        if (!raycastDest) {
            throw new Error('No scan mesh!');
        }
        const hit = this._raycaster.intersectObject(raycastDest); // Check only selected scan mesh
        if (hit.length > 0) {
            if (showDebug) {
                const arrowHelper = new ArrowHelper(
                    hit[0].point.sub(new Vector3(origin.x, origin.y, 0)),
                    new Vector3(origin.x, origin.y, 0),
                    Math.abs(hit[0].distance + origin.z),
                    '#0099ff',
                    0.001,
                    0.00025,
                );
                this._debugObjects.push(arrowHelper);
            }
            return hit[0].distance + origin.z;
        }
        return null;
    }

    private onKeyEvent(event: KeyboardEvent): void {
        // Shortcut to compute morphing grid with debug (toggle), logged into console
        if (event.key === 'g' && event.altKey && event.ctrlKey) {
            // Ctrl + alt + g
            if (ScanController.instance) {
                if (this._debugObjects.length > 0) {
                    this.dispose();
                } else {
                    console.info(this.getMorphingOnFoot(ScanController.instance.isRightFoot, true));
                }
            }
        }
    }

    dispose(): void {
        // Remove previous temporary meshes
        this._debugObjects.forEach((object3D) => MeshUtils.disposeObject(object3D));
        this._debugObjects.length = 0;
    }
}

export default MorphingTool;
