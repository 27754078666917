<script setup lang="ts">
export interface Props {
    id: string;
    label: string;
    groupName?: string;
    modelValue: boolean | null;
    disabled?: boolean;
    labelClass?: string;
}

const props = withDefaults(defineProps<Props>(), {
    label: '',
    groupName: '',
    modelValue: undefined,
    disabled: false,
    labelClass: '',
});

const emit = defineEmits<{
    'update:modelValue': [value: boolean];
}>();

function emitValue(value: Event) {
    if (props.disabled) {
        return;
    }
    const target = value.target as HTMLInputElement;
    emit('update:modelValue', target.checked);
}
</script>

<template>
    <div class="checkbox flex items-center gap-2">
        <input
            :id="props.id"
            class="substituted m-0 inline h-0 w-0 appearance-none"
            :name="props.id ?? props.groupName"
            :model-value="!!props.modelValue"
            :disabled="props.disabled"
            :checked="!!props.modelValue"
            type="checkbox"
            data-test="checkbox-input"
            @change.prevent="emitValue"
        />

        <label
            :class="[
                'flex items-center gap-2 font-semibold',
                disabled && 'opacity-70 hover:cursor-not-allowed',
                !disabled && 'hover:cursor-pointer',
            ]"
            :for="props.id"
        >
            <span :class="labelClass">
                {{ props.label }}
            </span></label
        >
    </div>
</template>

<style scoped>
.checkbox *,
.checkbox ::after,
.checkbox ::before {
    box-sizing: border-box;
}

.checkbox [type='checkbox'].substituted {
    margin: 0;
    width: 0;
    height: 0;
    display: inline;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.checkbox [type='checkbox'].substituted + label:before {
    content: '';
    @apply inline-block h-4 w-4 rounded border-2 border-theme-black text-theme-black;

    vertical-align: top;
    background:
        url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="white" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>')
            no-repeat center,
        white;
    background-size: 0;
    will-change: color, border, background, background-size, box-shadow;
    transform: translate3d(0, 0, 0);
    transition:
        color 0.1s,
        border 0.1s,
        background 0.15s,
        box-shadow 0.1s;
}

.checkbox [type='checkbox'].substituted:checked + label:before {
    @apply bg-theme-blue-500 text-theme-black;

    background-size: 0.75em;
}

.checkbox [type='checkbox'].substituted:disabled + label:before {
    @apply opacity-50;
}

.checkbox [type='checkbox'].substituted.dark + label:before {
    color: rgba(255 255 255 / 27.5%);
    background-color: #222;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="rgba(34, 34, 34, 0.999)" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>');
}

.checkbox [type='checkbox'].substituted.dark:checked + label:before {
    background-color: #a97035;
    color: rgba(255 255 255 / 7.5%);
}

.checkbox [type='checkbox'].substituted:checked:enabled:active + label:before,
.checkbox [type='checkbox'].substituted:checked:enabled + label:active:before {
    @apply bg-theme-blue-500 text-theme-black;
}

.checkbox [type='checkbox'].substituted.dark:enabled:active + label:before,
.checkbox [type='checkbox'].substituted.dark:enabled + label:active:before {
    background-color: #444;
}

.checkbox [type='checkbox'].substituted.dark:checked:enabled:active + label:before,
.checkbox [type='checkbox'].substituted.dark:checked:enabled + label:active:before {
    background-color: #c68035;
    color: rgba(0 0 0 / 27.5%);
}
</style>
