<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';

const props = defineProps<{
    min: number;
    max: number;
    modelValue: string | number;
    showValue?: Boolean;
}>();

const emit = defineEmits<{
    'update:modelValue': [value: string | number];
    'input': [void];
}>();

const rangeInput = ref<HTMLInputElement>();
const thumbFollowingDivWrap = ref<HTMLDivElement>();
const thumbFollowingDiv = ref<HTMLDivElement>();

onMounted(() => {
    if (rangeInput.value && thumbFollowingDiv.value && thumbFollowingDivWrap.value) {
        const moveSliderValue = () => {
            const thumbPosition =
                ((Number(props.modelValue) - props.min) / (props.max - props.min)) *
                thumbFollowingDivWrap.value!.offsetWidth;
            thumbFollowingDiv.value!.style.left = `${thumbPosition}px`;
        };

        moveSliderValue();

        rangeInput.value.addEventListener('input', moveSliderValue);
    }
});

const cssVars = computed(() => {
    return {
        width: '100%',
        '--value': props.modelValue,
        '--min': props.min,
        '--max': props.max,
    };
});

function onInput(event: Event) {
    const target = event.target as HTMLInputElement;
    if (typeof props.modelValue === 'number') {
        emit('update:modelValue', Number(target.value));
    } else {
        emit('update:modelValue', target.value);
    }
    emit('input');
}
</script>
<template>
    <div :style="cssVars" class="flex w-full flex-col">
        <input
            id="parametersSlider"
            ref="rangeInput"
            :value="modelValue"
            :class="['w-full appearance-none self-center bg-transparent']"
            type="range"
            :min="min"
            :max="max"
            @input="onInput"
        />
        <div v-if="showValue" ref="thumbFollowingDivWrap" class="slider-value-wrap">
            <div ref="thumbFollowingDiv" class="slider-value">{{ modelValue }}%</div>
        </div>
    </div>
</template>

<style scoped>
input[type='range'] {
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--value) - var(--min)) / var(--range));
    --sx: calc(0.5 * 2em + var(--ratio) * (100% - 2em));
    @apply mx-0 my-2 h-9 w-full appearance-none overflow-hidden;
}

input[type='range']:focus {
    @apply outline-none;
}

input[type='range']::-webkit-slider-runnable-track {
    @apply h-1 w-full cursor-pointer rounded-xl border-none;

    background:
        linear-gradient(#08729b, #08729b) 0 / var(--sx) 100% no-repeat,
        #aeb2a8;
}

input.no-data[type='range']::-webkit-slider-thumb {
    @apply bg-primary;
}

input[type='range']::-webkit-slider-thumb {
    @apply -mt-1.5 h-4 w-4 cursor-pointer appearance-none rounded-full border-4 border-primary bg-primary shadow-2xl;
}

input[type='range']::-moz-range-track {
    @apply h-1 w-full cursor-pointer rounded-lg border-none bg-border;
}

input.no-data[type='range']::-moz-range-thumb {
    @apply bg-primary;
}

input[type='range']::-moz-range-thumb {
    @apply h-4 w-4 cursor-pointer rounded-full border-2 border-primary bg-primary;
}

input[type='range']::-moz-range-progress {
    @apply bg-primary;
}

input[type='range']::-ms-track {
    @apply h-1 w-full cursor-pointer border-transparent bg-transparent text-transparent;
}

input[type='range']::-ms-fill-lower {
    @apply rounded-2xl border-none bg-border;
}

input[type='range']::-ms-fill-upper {
    @apply rounded-2xl border-none bg-primary;
}

input.no-data[type='range']::-ms-thumb {
    @apply bg-primary;
}

input[type='range']::-ms-thumb {
    @apply mt-px h-4 w-4 cursor-pointer rounded-full border-2 border-primary bg-primary;
}

input[type='range']:focus::-ms-fill-lower {
    @apply bg-white;
}

input[type='range']:focus::-ms-fill-upper {
    @apply bg-white;
}

.slider-value-wrap {
    @apply relative h-2 w-full self-center px-4;
}

.slider-value {
    @apply absolute -top-2 w-fit -translate-x-1/2 rounded-xl border border-border bg-white px-2 py-1;
}
</style>
