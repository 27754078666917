import HydraAPI from '@/domains/common/services/hydraApi';
import { applyPaginationParams } from '@/domains/common/services/utils/buildFilterParams';
import transformDTOtoOfficeInvitation from '@/domains/office/transformers/transformDTOtoOfficeInvitation';
import { handleHttpError } from '@/domains/common/services/errorHandler';
import type PaginatedEntitiesInterface from '@/domains/common/typescript/PaginatedEntitiesInterface';
import type RegistrationFormInterface from '@/domains/authentication/typescript/RegistrationFormInterface';
import type OfficeInvitationInterface from '@/domains/office/typescript/OfficeInvitationInterface';
import type OfficeInvitationDTOCollectionInterface from '@/domains/office/typescript/OfficeInvitationDTOCollectioninterface';
import type OfficeInvitationFilterInterface from '@/domains/office/typescript/OfficeInvitationFilterInterface';
import type OfficeInvitationDTOInterface from '@/domains/office/typescript/OfficeInvitationDTOInterface';
import type OfficeInvitationFormDTOInterface from '@/domains/office/typescript/OfficeInvitationFormDTOInterface';

const API_URI = import.meta.env.VITE_API_URI;

const createInvitation = async (
    invitationForm: OfficeInvitationFormDTOInterface,
): Promise<OfficeInvitationInterface> => {
    const url = '/office_user_invitations';
    const body = JSON.stringify(invitationForm);
    const data = await HydraAPI.postJson<OfficeInvitationDTOInterface>(url, { body });

    return transformDTOtoOfficeInvitation(data);
};

const acceptInvitationRegister = async (token: string, form?: RegistrationFormInterface): Promise<any> => {
    const url = `${API_URI}/office_user_invitations/${token}/accept/register`;

    let body = '{}';
    if (form) {
        body = JSON.stringify({ firstName: form.firstname, lastName: form.lastname, password: form.password });
    }

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body,
    });

    if (!response.ok) {
        const error = await response.json();
        handleHttpError(response.status, error.code_error);
    }
};

const acceptInvitation = async (token: string, loginEmail: string): Promise<any> => {
    const url = `${API_URI}/office_user_invitations/${token}/accept`;

    const body = JSON.stringify({ loginEmail });

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body,
    });

    if (!response.ok) {
        const error = await response.json();
        handleHttpError(response.status, error.code_error);
    }
};

const getInvitations = async (
    filters: OfficeInvitationFilterInterface,
): Promise<PaginatedEntitiesInterface<OfficeInvitationInterface>> => {
    const params = applyPaginationParams({ page: filters.page, limit: filters.limit });

    if (filters.officeId) {
        params.append('office', filters.officeId);
    }
    const url = `/office_user_invitations?${params.toString()}`;
    const data = await HydraAPI.getJson<OfficeInvitationDTOCollectionInterface>(url);

    const invitations = data['hydra:member'].map((invitation) => transformDTOtoOfficeInvitation(invitation));
    return { entities: invitations, total: data['hydra:totalItems'] };
};

const deleteOfficeInvitation = async (invitation: OfficeInvitationInterface) => {
    const url = `/office_user_invitations/${invitation.id}`;
    await HydraAPI.deleteJson(url);
};

export default {
    createInvitation,
    acceptInvitation,
    acceptInvitationRegister,
    getInvitations,
    deleteOfficeInvitation,
};
