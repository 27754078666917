<script setup lang="ts" generic="T extends Date | Date[]">
import IconCalendar from '@/domains/common/components/icons/IconCalendar.vue';
import { computed, ref } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import { fr } from 'date-fns/locale';
import { isEqual, set, format, isBefore } from 'date-fns';
import type SelectOptionInterface from '@/domains/common/typescript/SelectOptionInterface';

interface Props {
    modelValue?: T | null;
    placeholder?: string;
    name?: string;
    placeholderOnly?: boolean;
    showChip?: boolean;
    disabled?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
    modelValue: null,
    placeholder: undefined,
    name: '',
    disabled: false,
});

const selectedDate = computed<T | null>(() => props.modelValue);
const selectedMonth = ref(0);
const isInputFocused = ref<boolean>(false);
const monthOptions = ref<SelectOptionInterface<number>[]>([]);
const yearOptions = ref<SelectOptionInterface<number>[]>([]);

const getDayClass = (date: Date) => {
    const classes = ['w-8 h-8 text-sm my-0 font-medium relative'];

    if (isDateSelected(date)) {
        classes.push('rounded-full bg-button-hover');
        if (isFirstInrange(date)) {
            classes.push('before:absolute before:bg-button before:w-5 before:h-8 before:z-[-1] before:-right-1');
        }
        if (isLastInrange(date)) {
            classes.push('before:absolute before:bg-button before:w-5 before:h-8 before:z-[-1] before:-left-1');
        }
    } else if (isDateInRange(date)) {
        classes.push('bg-button rounded-none');
    } else {
        classes.push('hover:rounded-full hover:bg-button');
    }

    if (date.getMonth() === selectedMonth.value) {
        classes.push('text-black');
    } else {
        classes.push('text-border');
    }
    return classes.join(' ');
};

const formatedDate = computed((): string => {
    if (props.placeholderOnly) {
        return '';
    }
    if (selectedDate?.value && selectedDate.value instanceof Date) {
        return format(selectedDate.value, 'dd/MM/yyyy');
    }
    if (selectedDate?.value && Array.isArray(selectedDate.value)) {
        return selectedDate.value
            .map((date) => {
                return format(date, 'dd/MM/yyyy');
            })
            .join(' - ');
    }
    return '';
});

const isRange = computed(() => {
    return Array.isArray(selectedDate.value);
});

const startDate = computed((): Date => {
    if (Array.isArray(selectedDate.value) && selectedDate.value.length > 0) {
        return selectedDate.value[0];
    }
    if (!Array.isArray(selectedDate.value) && selectedDate?.value) {
        return selectedDate.value;
    }
    return new Date();
});

const isChipVisible = computed(() => {
    if (Array.isArray(selectedDate.value) && selectedDate.value.length > 0) {
        return props.showChip;
    }
    if (!Array.isArray(selectedDate.value) && selectedDate?.value) {
        return props.showChip;
    }
    return false;
});

const chipCount = computed(() => {
    if (Array.isArray(selectedDate.value) && selectedDate.value.length > 0) {
        return selectedDate.value.length;
    }
    if (!Array.isArray(selectedDate.value) && selectedDate?.value) {
        return 1;
    }
    return 0;
});

function handleMonthYear(params: { month: number }) {
    selectedMonth.value = params.month;
}

function onOpen() {
    selectedMonth.value = startDate.value.getMonth();
    isInputFocused.value = true;
}

function onClose() {
    selectedMonth.value = startDate.value.getMonth();
    isInputFocused.value = false;
}

function onDateChange(newDate: Date | null) {
    emit('update:modelValue', newDate as T);
}

function onRangeStart(startRange: Date) {
    emit('update:modelValue', [startRange] as T);
}

function onRangeEnd(endDate: Date) {
    if (selectedDate.value && Array.isArray(selectedDate.value)) {
        const range = [...selectedDate.value, endDate].sort((a, b) => (isBefore(a, b) ? -1 : 1));
        emit('update:modelValue', range as T);
    }
}

function isDateSelected(date: Date) {
    if (selectedDate?.value && selectedDate.value instanceof Date) {
        return isEqual(date, set(selectedDate.value, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }));
    }
    if (selectedDate?.value && Array.isArray(selectedDate.value)) {
        return selectedDate.value.some((currentDate) =>
            isEqual(date, set(currentDate, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
        );
    }
    return false;
}

function isDateInRange(date: Date) {
    if (selectedDate?.value && Array.isArray(selectedDate.value) && selectedDate.value.length === 2) {
        return date > selectedDate.value[0] && date < selectedDate.value[1];
    }
    return false;
}

function isFirstInrange(date: Date) {
    if (selectedDate?.value && Array.isArray(selectedDate.value) && selectedDate.value.length === 2) {
        return isEqual(date, set(selectedDate.value[0], { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }));
    }
    return false;
}

function isLastInrange(date: Date) {
    if (selectedDate?.value && Array.isArray(selectedDate.value) && selectedDate.value.length === 2) {
        return isEqual(date, set(selectedDate.value[1], { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }));
    }
    return false;
}

function getMonthOptions(months: { value: number; text: string }[]) {
    if (monthOptions.value.length === 0) {
        monthOptions.value = buildMonthYearOptions(months);
    }
    return monthOptions.value;
}

function getYearOptions(years: { value: number; text: string }[]) {
    if (yearOptions.value.length === 0) {
        yearOptions.value = buildMonthYearOptions(years);
    }
    return yearOptions.value;
}

function buildMonthYearOptions(monthsYears: { value: number; text: string }[]) {
    return monthsYears.map((monthYear) => {
        return {
            value: monthYear.value,
            label: monthYear.text,
        };
    });
}

const emit = defineEmits<{
    'update:modelValue': [value: T | null];
}>();
</script>

<template>
    <VueDatePicker
        :value="selectedDate"
        auto-apply
        locale="fr"
        :format-locale="fr"
        no-today
        menu-class-name="w-auto h-auto rounded-xl shadow-shadow shadow-drop z-10 border !border-border"
        :enable-time-picker="false"
        :day-class="getDayClass"
        :action-row="{ showCancel: false, showPreview: false, showSelect: false, showNow: false }"
        :day-names="['L', 'M', 'M', 'J', 'V', 'S', 'D']"
        :range="isRange"
        month-name-format="long"
        :start-date="startDate"
        :disabled="disabled"
        @date-update="onDateChange"
        @range-start="onRangeStart"
        @range-end="onRangeEnd"
        @update-month-year="handleMonthYear"
        @open="onOpen"
        @closed="onClose"
    >
        <template #dp-input>
            <div
                :class="[
                    'group flex  items-center overflow-hidden rounded-2xl',
                    disabled && 'cursor-not-allowed bg-gray-300',
                    !disabled && 'cursor-pointer bg-white',
                ]"
            >
                <input
                    id="calendar"
                    :value="formatedDate"
                    :name="name"
                    type="text"
                    :placeholder="placeholder"
                    :class="[
                        'pointer-events-none h-10 grow appearance-none border-none pl-4 text-sm shadow-none placeholder:font-semibold placeholder:text-theme-black',
                        disabled && 'bg-gray-300',
                    ]"
                />
                <IconCalendar :class="[!disabled && 'group-hover:text-button-hover']" />
                <span
                    v-if="isChipVisible"
                    class="mr-5 flex h-5 w-5 items-center justify-center rounded-full bg-chip text-sm group-hover:bg-chip-hover"
                >
                    {{ chipCount }}
                </span>
            </div>
        </template>
        <template #month-year="{ month, year, months, years, updateMonthYear }">
            <div class="align-center flex w-full flex-row items-center justify-center">
                <BaseSelect
                    class="w-auto"
                    mode="noShadow"
                    :model-value="month"
                    :options="getMonthOptions(months)"
                    @update:model-value="updateMonthYear($event, year)"
                />
                <BaseSelect
                    class="w-auto"
                    mode="noShadow"
                    :model-value="year"
                    :options="getYearOptions(years)"
                    @update:model-value="updateMonthYear(month, $event)"
                />
            </div>
        </template>
        <template #calendar-header="{ day }">
            <div class="h-7 w-8 p-1.5 text-sm font-bold">
                {{ day }}
            </div>
        </template>
    </VueDatePicker>
</template>

<style>
/* stylelint-disable selector-class-pattern */
.dp__main {
    width: auto;
    font-family: inherit;
}

.dp__calendar_header_separator {
    display: none;
}

.dp__calendar_header_item {
    width: 2rem;
    height: 1.75rem;
    text-align: center;
    margin: 0;
    padding: 0;
}

.dp__arrow_top {
    display: none;
}

.dp__calendar_item {
    z-index: 1;
}

.dp__calendar_row {
    margin: 0;
}
/* stylelint-enable selector-class-pattern */
</style>
