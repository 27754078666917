import API from './api';
import type HydraDTOCollectionInterface from '@/domains/common/typescript/HydraDTOCollectionInterface';
import type HydraDTOInterface from '@/domains/common/typescript/HydraDTOInterface';

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["getJson", "postJson", "postBlob", "putJson", "postFormData", "patchJson", "deleteJson"] }] */
class HAPI {
    static buildAuthenticatedHeaders(): Headers {
        const headers = new Headers();

        headers.set('Content-Type', 'application/ld+json');

        return headers;
    }

    public async getJson<T extends HydraDTOCollectionInterface | HydraDTOInterface>(
        url: string,
        request?: RequestInit,
        options: { triggerNotification?: boolean } = { triggerNotification: true },
    ): Promise<T> {
        const headers = HAPI.buildAuthenticatedHeaders();
        return API.getJson<T>(url, request, headers, options);
    }

    public async postJson<T extends HydraDTOInterface>(url: string, request?: RequestInit): Promise<T> {
        const headers = HAPI.buildAuthenticatedHeaders();
        return API.postJson<T>(url, request, headers);
    }

    public async postBlob(url: string, request?: RequestInit): Promise<Blob> {
        const headers = HAPI.buildAuthenticatedHeaders();
        return API.postBlob(url, request, headers);
    }

    public async putJson<T extends HydraDTOInterface>(url: string, request?: RequestInit): Promise<T> {
        const headers = HAPI.buildAuthenticatedHeaders();
        return API.putJson<T>(url, request, headers);
    }

    public async postFormData<T extends HydraDTOInterface>(url: string, body: FormData): Promise<T> {
        return API.postFormData<T>(url, body);
    }

    public async patchJson<T extends HydraDTOInterface>(url: string, request?: RequestInit): Promise<T> {
        const headers = HAPI.buildAuthenticatedHeaders();
        return API.patchJson<T>(url, request, headers);
    }

    public async deleteJson(url: string, request?: RequestInit): Promise<void> {
        const headers = HAPI.buildAuthenticatedHeaders();
        return API.deleteJson(url, request, headers);
    }
}

const HydraAPI = new HAPI();

export default HydraAPI;
