<template>
    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.81364 0H11C12.1046 0 13 0.89543 13 2V13.6C13 14.7046 12.1046 15.6 11 15.6H2C0.89543 15.6 0 14.7046 0 13.6V3.7683C0 3.22908 0.217725 2.71272 0.6038 2.3363L2.41744 0.568C2.79095 0.203825 3.29197 0 3.81364 0ZM4.33333 0.366626C4.60948 0.366626 4.83333 0.590484 4.83333 0.866626V3.33329C4.83333 4.16172 4.16176 4.83329 3.33333 4.83329H0.866666C0.590523 4.83329 0.366666 4.60944 0.366666 4.33329C0.366666 4.05715 0.590523 3.83329 0.866666 3.83329H3.33333C3.60947 3.83329 3.83333 3.60944 3.83333 3.33329V0.866626C3.83333 0.590484 4.05719 0.366626 4.33333 0.366626ZM6.25 4.24999H7H9C9.41421 4.24999 9.75 4.58577 9.75 4.99999C9.75 5.4142 9.41422 5.74999 9 5.74999H7H6.25V4.24999ZM3.75 7.49999C3.33579 7.49999 3 7.83577 3 8.24999C3 8.6642 3.33579 8.99999 3.75 8.99999H9.25C9.66421 8.99999 10 8.6642 10 8.24999C10 7.83577 9.66421 7.49999 9.25 7.49999H3.75ZM3 11.25C3 10.8358 3.33579 10.5 3.75 10.5H9.25C9.66421 10.5 10 10.8358 10 11.25C10 11.6642 9.66421 12 9.25 12H3.75C3.33579 12 3 11.6642 3 11.25Z"
            fill="currentColor"
        />
    </svg>
</template>
