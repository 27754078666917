<template>
    <svg width="34" height="36" viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.5 10.75C14.5 10.3358 14.1642 10 13.75 10C13.3358 10 13 10.3358 13 10.75V12H12.75C11.2312 12 10 13.2312 10 14.75V22.75C10 24.2688 11.2312 25.5 12.75 25.5H20.75C22.2688 25.5 23.5 24.2688 23.5 22.75V19.4167V14.75C23.5 13.2312 22.2688 12 20.75 12H20.5V10.75C20.5 10.3358 20.1642 10 19.75 10C19.3358 10 19 10.3358 19 10.75V12H14.5V10.75ZM19 13.75V13.5H14.5V13.75C14.5 14.1642 14.1642 14.5 13.75 14.5C13.3358 14.5 13 14.1642 13 13.75V13.5H12.75C12.0596 13.5 11.5 14.0596 11.5 14.75V16L22 16V14.75C22 14.0596 21.4404 13.5 20.75 13.5H20.5V13.75C20.5 14.1642 20.1642 14.5 19.75 14.5C19.3358 14.5 19 14.1642 19 13.75ZM11.5 22.75V17.5L22 17.5V19.4167V22.75C22 23.4404 21.4404 24 20.75 24H12.75C12.0596 24 11.5 23.4404 11.5 22.75ZM13.75 21.75C14.3023 21.75 14.75 21.3023 14.75 20.75C14.75 20.1977 14.3023 19.75 13.75 19.75C13.1977 19.75 12.75 20.1977 12.75 20.75C12.75 21.3023 13.1977 21.75 13.75 21.75ZM17.75 20.75C17.75 21.3023 17.3023 21.75 16.75 21.75C16.1977 21.75 15.75 21.3023 15.75 20.75C15.75 20.1977 16.1977 19.75 16.75 19.75C17.3023 19.75 17.75 20.1977 17.75 20.75ZM19.75 21.75C20.3023 21.75 20.75 21.3023 20.75 20.75C20.75 20.1977 20.3023 19.75 19.75 19.75C19.1977 19.75 18.75 20.1977 18.75 20.75C18.75 21.3023 19.1977 21.75 19.75 21.75Z"
            fill="currentColor"
        />
    </svg>
</template>
