import type PodonexConfigurationInterface from '@/domains/settings/typescript/interfaces/PodonexConfigurationInterface';
import type PodonexConfigurationDTOInterface from '@/domains/settings/typescript/interfaces/PodonexConfigurationDTOInterface';

export default function transformPodonexConfigurationtoDTO(
    settings: PodonexConfigurationInterface,
): PodonexConfigurationDTOInterface {
    return {
        id: settings.id,
        scanner: settings.scannerId,
        lightSensibility: settings.lightSensibility,
        brightness: settings.brightness,
        contrast: settings.contrast,
        threshold: Number(settings.sobelThreshold),
        steps: Number(settings.cleaningSteps),
        owner: settings.userId,
        macBrightness: settings.macBrightness,
        macContrast: settings.macContrast,
    } as PodonexConfigurationDTOInterface;
}
