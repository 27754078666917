import BaseButton from '@/domains/common/components/BaseButton.vue';
import BaseButtonRouterLink from '@/domains/common/components/BaseButtonRouterLink.vue';
import BaseButtonUpload from '@/domains/common/components/BaseButtonUpload.vue';
import BaseCard from '@/domains/common/components/BaseCard.vue';
import BaseCheckbox from '@/domains/common/components/BaseCheckbox.vue';
import BaseChip from '@/domains/common/components/BaseChip.vue';
import BaseCopyClipboard from '@/domains/common/components/BaseCopyClipboard.vue';
import BaseDatePicker from '@/domains/common/components/BaseDatePicker.vue';
import BaseErrorMessage from '@/domains/common/components/BaseErrorMessage.vue';
import BaseInput from '@/domains/common/components/BaseInput.vue';
import BaseInputLabel from '@/domains/common/components/BaseInputLabel.vue';
import BaseInputPassword from '@/domains/common/components/BaseInputPassword.vue';
import BaseLink from '@/domains/common/components/BaseLink.vue';
import BaseListItem from '@/domains/common/components/BaseListItem.vue';
import BaseModal from '@/domains/common/components/BaseModal.vue';
import BasePagination from '@/domains/common/components/BasePagination.vue';
import BaseSelect from '@/domains/common/components/BaseSelect.vue';
import BaseSelectAutocomplete from '@/domains/common/components/BaseSelectAutocomplete.vue';
import BaseSkeleton from '@/domains/common/components/BaseSkeleton.vue';
import BaseSlider from '@/domains/common/components/BaseSlider.vue';
import BaseSortListHeader from '@/domains/common/components/BaseSortListHeader.vue';
import BaseSwitch from '@/domains/common/components/BaseSwitch.vue';
import BaseTooltip from '@/domains/common/components/BaseTooltip.vue';
import type { App } from 'vue';

function registerBaseComponents(app: App) {
    app.component('BaseButton', BaseButton);
    app.component('BaseButtonRouterLink', BaseButtonRouterLink);
    app.component('BaseButtonUpload', BaseButtonUpload);
    app.component('BaseCard', BaseCard);
    app.component('BaseCheckbox', BaseCheckbox);
    app.component('BaseChip', BaseChip);
    app.component('BaseCopyClipboard', BaseCopyClipboard);
    app.component('BaseDatePicker', BaseDatePicker);
    app.component('BaseErrorMessage', BaseErrorMessage);
    app.component('BaseInput', BaseInput);
    app.component('BaseInputLabel', BaseInputLabel);
    app.component('BaseInputPassword', BaseInputPassword);
    app.component('BaseLink', BaseLink);
    app.component('BaseListItem', BaseListItem);
    app.component('BaseModal', BaseModal);
    app.component('BasePagination', BasePagination);
    app.component('BaseSelect', BaseSelect);
    app.component('BaseSelectAutocomplete', BaseSelectAutocomplete);
    app.component('BaseSkeleton', BaseSkeleton);
    app.component('BaseSlider', BaseSlider);
    app.component('BaseSortListHeader', BaseSortListHeader);
    app.component('BaseSwitch', BaseSwitch);
    app.component('BaseTooltip', BaseTooltip);
}

export { registerBaseComponents };
