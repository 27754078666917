<script setup lang="ts">
import SkeletonShapeEnum from '@/domains/common/typescript/SkeletonShapeEnum';
import { computed, toRefs } from 'vue';

const LOADER_CSS_CLASSES = {
    [SkeletonShapeEnum.Rectangle]: 'rounded-xl',
    [SkeletonShapeEnum.Circle]: 'rounded-full',
};

const SHIMMER_COLOR = '#ffffff';

const isHexColor = (hexColor: string) => {
    const hex = hexColor.replace('#', '');

    return (
        typeof hexColor === 'string' &&
        hexColor.startsWith('#') &&
        hex.length === 6 &&
        !Number.isNaN(Number(`0x${hex}`))
    );
};

const hexToRgb = (hex: string) => `${hex.match(/\w\w/g)?.map((x) => +`0x${x}`)}`;

const props = withDefaults(
    defineProps<{
        type?: SkeletonShapeEnum;
        bgClass?: string;
        cssClass?: string;
        shimmerColor?: string;
        duration?: string;
    }>(),
    {
        type: SkeletonShapeEnum.Rectangle,
        bgClass: 'bg-gray-300',
        cssClass: '',
        shimmerColor: SHIMMER_COLOR,
        duration: '1.4s',
    },
);

const { type, bgClass, cssClass, shimmerColor } = toRefs(props);

const shimmerStyle = computed(() => {
    const rgb = isHexColor(shimmerColor.value) ? hexToRgb(shimmerColor.value) : SHIMMER_COLOR;

    return {
        backgroundImage: `linear-gradient(90deg, rgba(${rgb}, 0) 0%, rgba(${rgb}, 0.2) 20%, rgba(${rgb}, 0.5) 60%, rgba(${rgb}, 0))`,
    };
});

const loaderClass = computed(() => (cssClass.value ? cssClass.value : LOADER_CSS_CLASSES[type.value]));
</script>

<template>
    <div :class="[bgClass, loaderClass, 'relative overflow-hidden']">
        <div class="shimmer absolute bottom-0 left-0 right-0 top-0" :style="shimmerStyle"></div>
        <slot />
    </div>
</template>

<style lang="css" scoped>
.shimmer {
    transform: translateX(-100%);
    animation: shimmer v-bind(duration) infinite;
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}
</style>
