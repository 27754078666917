<script setup lang="ts">
import IconArrowFilledDown from './icons/IconArrowFilledDown.vue';
import IconArrowFilledUp from './icons/IconArrowFilledUp.vue';
import SortParamsEnum from '@/domains/common/typescript/SortParamsEnum';
import { ListHeaderAlignEnum } from '@/domains/common/typescript/ListHeaderAlignEnum';
import { computed } from 'vue';

interface Props {
    modelValue: { param: string; value: SortParamsEnum };
    headers: { label: string; param?: string; align?: ListHeaderAlignEnum }[];
}

const props = defineProps<Props>();

const emit = defineEmits<{
    'update:modelValue': [value: { param: string; value: SortParamsEnum }];
}>();

function sortBy(paramName: string) {
    if (!paramName) {
        return;
    }

    let sortByParam = { param: paramName, value: SortParamsEnum.ASC };

    if (props.modelValue.param === paramName) {
        sortByParam = {
            param: paramName,
            value: props.modelValue.value === SortParamsEnum.ASC ? SortParamsEnum.DESC : SortParamsEnum.ASC,
        };
    }

    emit('update:modelValue', sortByParam);
}

const isSortedAsc = computed(() => props.modelValue.value === SortParamsEnum.ASC);
const isSortedDesc = computed(() => props.modelValue.value === SortParamsEnum.DESC);
</script>
<template>
    <div :class="[$attrs.class, 'z-[1]']">
        <button
            v-for="(column, index) in headers"
            :key="index"
            :class="[
                'flex appearance-none items-center gap-1 uppercase',
                column.align === ListHeaderAlignEnum.LEFT && 'justify-start',
                column.align === ListHeaderAlignEnum.RIGHT && 'justify-end',
                (column.align === ListHeaderAlignEnum.CENTER || !column.align) && 'justify-center',
                !column.param && 'hover:cursor-default',
            ]"
            @click="column.param && sortBy(column.param)"
        >
            <span>{{ column.label }}</span>
            <div v-if="column.param">
                <IconArrowFilledUp
                    :class="[modelValue.param === column.param && isSortedAsc && 'text-theme-green-500']"
                    aria-hidden="true"
                />
                <IconArrowFilledDown
                    :class="[modelValue.param === column.param && isSortedDesc && 'text-theme-green-500']"
                    aria-hidden="true"
                />
            </div>
        </button>
    </div>
</template>
