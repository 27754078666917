<script setup lang="ts" generic="T">
const props = withDefaults(
    defineProps<{ selected: boolean; id: T; allowMultiSelect?: boolean; isSelectDisabled?: boolean }>(),
    {
        allowMultiSelect: false,
        isSelectDisabled: false,
    },
);
const emit = defineEmits<{
    select: [{ id: T; selected: boolean }];
    dbClick: [];
}>();

function select(selected: boolean) {
    if (props.isSelectDisabled) {
        return;
    }
    emit('select', { id: props.id, selected });
}
</script>

<template>
    <div
        :class="[
            $attrs.class,
            'my-1 grid w-full items-center rounded-xl border-2 bg-white px-2 py-2 text-center shadow-drop',
            'hover:border-secondary hover:shadow-shadow-hover',
            'border-transparent shadow-shadow',
        ]"
        @dblclick="$emit('dbClick')"
    >
        <div v-if="allowMultiSelect" class="flex items-center gap-4">
            <BaseCheckbox
                :id="`select-${id}`"
                label=""
                :model-value="selected"
                class="cursor-pointer"
                :disabled="isSelectDisabled"
                @update:model-value="select($event)"
            />
        </div>
        <slot name="columns" />
        <div class="flex justify-end gap-4">
            <slot name="buttons" />
        </div>
    </div>
</template>
