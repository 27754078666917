<script setup lang="ts" generic="T">
import IconChevronDown from '@/domains/common/components/icons/IconChevronDown.vue';
import IconChevronUp from '@/domains/common/components/icons/IconChevronUp.vue';
import ColorEnum from '@/domains/common/typescript/ColorEnum';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { computed, ref, watch, watchEffect, type Ref } from 'vue';
import { useElementBounding, useElementVisibility } from '@vueuse/core';
import type SelectOptionInterface from '@/domains/common/typescript/SelectOptionInterface';

const props = withDefaults(
    defineProps<{
        mode?: 'text' | 'button' | 'mediumText' | 'noShadow';
        emptyValue?: string;
        options: SelectOptionInterface<T>[];
        modelValue: SelectOptionInterface<T>['value'] | SelectOptionInterface<T>['value'][] | null;
        color?: ColorEnum;
        disabled?: boolean;
        displayAbove?: boolean;
        onlyDefault?: boolean;
        condensed?: boolean;
        showChip?: boolean;
    }>(),
    {
        emptyValue: '',
        mode: 'button',
        color: ColorEnum.Primary,
        disabled: false,
        displayAbove: false,
        condensed: false,
        showChip: false,
    },
);

const colorClass: Record<ColorEnum, string> = {
    primary: 'border-transparent',
    secondary: 'border border-1 border-gray-400',
    primaryFilled: 'bg-primary rounded-full',
};

const optionButton = ref<HTMLDivElement>();
const isOpen = ref(false);

const { top, left, width, height } = useElementBounding(optionButton);
const isButtonVisible = useElementVisibility(optionButton);

const isMultiple = computed(() => Array.isArray(props.modelValue));

const optionsStyle = computed(() => {
    if (!isOpen.value) {
        return [];
    }
    const classes: Record<string, string> = {
        width: `${width.value}px`,
        left: `${left.value}px`,
    };
    let maxHeight = '240px';

    if (props.displayAbove) {
        classes.bottom = `${window.innerHeight - top.value}px`;
        if (top.value <= 240) {
            maxHeight = `${top.value - 12}px`;
        }
    } else {
        classes.top = `${top.value + height.value + 6}px`;
        const remainingSpace = window.innerHeight - (top.value + height.value);
        if (remainingSpace <= 240) {
            maxHeight = `${remainingSpace - 12}px`;
        }
    }
    classes.maxHeight = maxHeight;

    return classes;
});

const isChipVisible = computed(() => {
    if (Array.isArray(selectedValue.value) && selectedValue.value.length > 0) {
        return props.showChip;
    }
    if (!Array.isArray(selectedValue.value) && selectedValue.value) {
        return props.showChip;
    }
    return false;
});

const chipCount = computed(() => {
    if (Array.isArray(selectedValue.value) && selectedValue.value.length > 0) {
        return selectedValue.value.length;
    }
    if (!Array.isArray(selectedValue.value) && selectedValue.value) {
        return 1;
    }
    return 0;
});

const getOptionValueFromProps = (): SelectOptionInterface<T> | SelectOptionInterface<T>[] | null => {
    if (Array.isArray(props.modelValue)) {
        return props.options.filter((option: SelectOptionInterface<T>) => {
            if (Array.isArray(props.modelValue)) {
                return props.modelValue.some((modelValueItem) => modelValueItem === option.value);
            }
            return false;
        });
    }
    return props.options.find((option: SelectOptionInterface<T>) => option.value === props.modelValue) ?? null;
};

const emit = defineEmits<{
    'update:modelValue': [value: T | T[] | null];
}>();

const selectedValue = ref<SelectOptionInterface<T> | SelectOptionInterface<T>[] | null>(
    getOptionValueFromProps(),
) as Ref<SelectOptionInterface<T> | SelectOptionInterface<T>[] | null>;

const displayedValue = computed(() => {
    if (props.options.length === 0 || props.onlyDefault) {
        return props.emptyValue;
    }

    const matchingOption = props.options
        .filter((option: SelectOptionInterface<T>) => {
            if (selectedValue.value === null) {
                return false;
            }
            if (Array.isArray(selectedValue.value)) {
                return selectedValue.value.some((selectedValueItem) => selectedValueItem.value === option.value);
            }
            return option.value === selectedValue.value.value;
        })
        .map((option) => option.label);

    return matchingOption.length === 0 ? props.emptyValue : matchingOption.toString();
});

watch(selectedValue, (value) => {
    if (value === null) {
        emit('update:modelValue', null);
        return;
    }
    if (Array.isArray(value)) {
        emit(
            'update:modelValue',
            value.map((item) => item.value),
        );
        return;
    }
    emit('update:modelValue', value.value);
});

watchEffect(() => {
    selectedValue.value = getOptionValueFromProps();
});
</script>

<template>
    <Listbox
        v-model="selectedValue"
        :multiple="isMultiple"
        as="div"
        :class="[
            'flex min-h-[30px] items-center ring-2 ring-inset ring-transparent focus:outline-none focus:ring-primary',
            colorClass[props.color],
            $attrs.class,
        ]"
        :disabled="props.disabled"
    >
        <div ref="optionButton" class="h-full w-full">
            <ListboxButton
                v-slot="{ open }"
                :class="[
                    'h-full w-full border-transparent text-left font-bold uppercase text-gray-900 sm:leading-6',
                    !props.condensed && 'pl-3',
                    props.condensed && 'pl-2',
                    props.mode === 'text' && 'font-bold uppercase',
                    props.mode === 'button' && 'rounded-2xl py-1.5 sm:text-sm',
                    props.mode === 'noShadow' && 'sm:text-sm',
                    props.mode === 'mediumText' && 'font-medium normal-case',
                    props.color === ColorEnum.PrimaryFilled && 'text-white',
                    props.disabled && 'cursor-not-allowed',
                    !props.disabled && 'cursor-pointer',
                ]"
                data-test="select-button"
            >
                <div class="flex h-full w-full flex-row items-center justify-between gap-1">
                    <img
                        v-if="!Array.isArray(selectedValue) && selectedValue?.thumbnail"
                        :src="selectedValue?.thumbnail"
                    />
                    <span class="grow truncate" data-test="displayed-value" v-text="displayedValue" />
                    <span
                        :class="[
                            'pointer-events-none inset-y-0 right-0 flex items-center',
                            !props.condensed && 'pr-2',
                            props.condensed && 'pr-1',
                        ]"
                    >
                        <IconChevronUp
                            v-if="open"
                            :class="[
                                'h-5 w-5 ',
                                props.color !== ColorEnum.PrimaryFilled && 'text-gray-400',
                                props.color === ColorEnum.PrimaryFilled && 'text-white',
                            ]"
                            aria-hidden="true"
                        />
                        <IconChevronDown
                            v-else
                            :class="[
                                'h-5 w-5 ',
                                props.color !== ColorEnum.PrimaryFilled && 'text-gray-400',
                                props.color === ColorEnum.PrimaryFilled && 'text-white',
                            ]"
                            aria-hidden="true"
                        />
                    </span>
                    <span
                        v-if="isChipVisible"
                        class="mr-5 flex h-5 w-5 items-center justify-center rounded-full bg-chip text-sm group-hover:bg-chip-hover"
                    >
                        {{ chipCount }}
                    </span>
                </div>
            </ListboxButton>

            <transition
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <ListboxOptions
                    v-show="isButtonVisible"
                    :style="optionsStyle"
                    class="fixed z-10 overflow-auto rounded-xl bg-white p-1 shadow-lg ring-2 ring-border focus:outline-none sm:text-sm"
                >
                    <div @vue:mounted="isOpen = true" @vue:unmounted="isOpen = false">
                        <ListboxOption
                            v-for="(option, index) in props.options"
                            :key="index"
                            v-slot="{ active, selected }"
                            as="template"
                            :value="option"
                        >
                            <li
                                data-test="option"
                                :class="[
                                    'relative flex cursor-default select-none flex-row items-center justify-around rounded-lg px-2 text-center text-gray-900',
                                    'hover:cursor-pointer',
                                    selected && 'bg-shadow',
                                    !selected && 'bg-white',
                                    props.condensed && 'py-0',
                                    !props.condensed && 'py-2',
                                ]"
                            >
                                <img v-if="option.thumbnail" :src="option.thumbnail" />
                                <span
                                    :class="[
                                        (active || selected) && 'font-semibold',
                                        !active && !selected && 'font-normal',
                                        'block',
                                    ]"
                                    v-text="option.label"
                                />
                            </li>
                        </ListboxOption>
                    </div>
                </ListboxOptions>
            </transition>
        </div>
    </Listbox>
</template>

<style scoped>
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    margin-top: 8px;
    margin-bottom: 8px;
    box-shadow: inset 0 0 5px #edebdf;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb {
    background: #cbd5e1;
    border-radius: 5px;
}
</style>
