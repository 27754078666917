<script setup lang="ts">
import { forgottenPassword } from '@/domains/authentication/services/authenticate';
import AuthenticationLayout from '@/layouts/AuthenticationLayout.vue';
import ButtonColorEnum from '@/domains/common/typescript/ButtonColorEnum';
import router from '@/router';
import RouteNameEnum from '@/router/routeNameEnum';

async function onSubmit(event: Event) {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);
    const email = formData.get('email') as string;

    forgottenPassword(email);
}

function onCancel() {
    router.push({ name: RouteNameEnum.Login });
}
</script>

<template>
    <AuthenticationLayout>
        <div class="mx-auto w-full max-w-sm lg:w-96">
            <h1 class="text-center text-3xl uppercase">
                {{ $t('login.title_forgotten_password') }}
            </h1>
            <form action="#" method="POST" class="mt-10 space-y-6" @submit="onSubmit">
                <p class="px-4 text-justify">{{ $t('login.forgotten_password_description') }}</p>
                <div>
                    <label for="email" class="ml-8 block text-sm font-medium leading-6 text-gray-900">{{
                        $t('login.recovery_email')
                    }}</label>
                    <div class="mt-2">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autocomplete="email"
                            required="true"
                            class="block w-full rounded-2xl border-0 p-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div class="mt-10 flex gap-2.5">
                    <BaseButton
                        class="w-1/2"
                        :color="ButtonColorEnum.Secondary"
                        :label="$t('global.cancel')"
                        @click="onCancel"
                    />
                    <BaseButton
                        type="submit"
                        class="w-1/2 px-3 py-2 text-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        :color="ButtonColorEnum.Primary"
                        :label="$t('login.forgotten_password_submit')"
                    />
                </div>
            </form>
        </div>
    </AuthenticationLayout>
</template>
