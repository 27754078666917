<template>
    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.4696 12.7537C12.0606 14.3605 9.99252 15.375 7.6875 15.375C3.44181 15.375 0 11.9332 0 7.6875C0 3.44181 3.44181 0 7.6875 0C11.9332 0 15.375 3.44181 15.375 7.6875C15.375 8.89146 15.0982 10.0308 14.6048 11.0453L18.0404 13.5004L16.8485 15.1683L13.4696 12.7537ZM13.375 7.6875C13.375 10.8286 10.8286 13.375 7.6875 13.375C4.54638 13.375 2 10.8286 2 7.6875C2 4.54638 4.54638 2 7.6875 2C10.8286 2 13.375 4.54638 13.375 7.6875Z"
            fill="currentColor"
        />
    </svg>
</template>
