<script setup lang="ts">
import TooltipPositionEnum from '@/domains/common/typescript/TooltipPositionEnum';
import { computed, ref, type StyleValue } from 'vue';

const props = withDefaults(
    defineProps<{
        parent: Element;
        text: string;
        visible: boolean;
        position: TooltipPositionEnum;
    }>(),
    {
        position: TooltipPositionEnum.TOP,
    },
);

const tooltip = ref<HTMLDivElement>();

const tooltipPosition = computed<StyleValue>(() => {
    if (!tooltip.value) {
        return null;
    }
    const bounding = props.parent.getBoundingClientRect();
    const offset = 8;

    if (props.position === TooltipPositionEnum.TOP) {
        return {
            top: `${bounding.top - tooltip.value.clientHeight - offset}px`,
            left: `${computeLeftOffset()}px`,
        };
    }

    if (props.position === TooltipPositionEnum.BOTTOM) {
        return {
            top: `${bounding.bottom + offset}px`,
            left: `${computeLeftOffset()}px`,
        };
    }

    if (props.position === TooltipPositionEnum.LEFT) {
        return {
            top: `${bounding.top + bounding.height / 2 - tooltip.value.clientHeight / 2}px`,
            left: `${bounding.left - tooltip.value.clientWidth - offset}px`,
        };
    }

    if (props.position === TooltipPositionEnum.RIGHT) {
        return {
            top: `${bounding.top + bounding.height / 2 - tooltip.value.clientHeight / 2}px`,
            left: `${bounding.right + offset}px`,
        };
    }

    return null;
});

const outerTriangleStyle = computed(() => {
    if (!tooltip.value) {
        return null;
    }

    const parentBounding = props.parent.getBoundingClientRect();

    const style: StyleValue = {
        width: 0,
        height: 0,
        borderColor: 'var(--theme-blue-500)',
    };
    style.borderTopWidth = '6px';
    style.borderTopStyle = 'solid';
    style.borderLeft = '4px solid transparent';
    style.borderRight = '4px solid transparent';
    if (props.position === TooltipPositionEnum.TOP) {
        style.left = `${parentBounding.left + parentBounding.width / 2 - 2}px`;
        style.top = `${parentBounding.top - 7}px`;
        return style;
    }
    if (props.position === TooltipPositionEnum.LEFT) {
        style.left = `${parentBounding.left - 8}px`;
        style.top = `${parentBounding.top + parentBounding.height / 2 - 2}px`;
        style.transform = 'rotate(-90deg)';
        return style;
    }
    if (props.position === TooltipPositionEnum.RIGHT) {
        style.left = `${parentBounding.right + 2}px`;
        style.top = `${parentBounding.top + parentBounding.height / 2 - 2}px`;
        style.transform = 'rotate(90deg)';
        return style;
    }
    if (props.position === TooltipPositionEnum.BOTTOM) {
        style.left = `${parentBounding.left + parentBounding.width / 2 - 2}px`;
        style.top = `${parentBounding.bottom + 2}px`;
        style.transform = 'rotate(180deg)';
        return style;
    }
    return null;
});

function computeLeftOffset() {
    if (!tooltip.value) {
        return 0;
    }

    const bounding = props.parent.getBoundingClientRect();
    if (window.innerWidth - bounding.right < tooltip.value.clientWidth / 2) {
        return window.innerWidth - tooltip.value.clientWidth - 12;
    }

    if (bounding.left < tooltip.value.clientWidth / 2) {
        return 12;
    }

    return bounding.left - tooltip.value.clientWidth / 2 + bounding.width / 2;
}

const innerTriangleStyle = computed(() => {
    const style: StyleValue = {
        width: 0,
        height: 0,
        position: 'relative',
        borderColor: 'white',
    };
    style.top = '-4px';
    style.left = '-4px';
    style.borderTop = '6px solid white';
    style.borderLeft = '4px solid transparent';
    style.borderRight = '4px solid transparent';
    if (props.position === TooltipPositionEnum.TOP) {
        return style;
    }
    if (props.position === TooltipPositionEnum.LEFT) {
        style.transform = 'rotate(-90deg)';
        return style;
    }
    if (props.position === TooltipPositionEnum.RIGHT) {
        style.transform = 'rotate(90deg)';
        return style;
    }
    if (props.position === TooltipPositionEnum.BOTTOM) {
        style.transform = 'rotate(180deg)';
        return style;
    }
    return null;
});
</script>
<template>
    <transition-group
        leave-active-class="transition-[opacity] ease-in duration-200"
        enter-active-class="transition-[opacity] ease-in duration-200"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div
            v-if="visible"
            ref="tooltip"
            :style="tooltipPosition"
            class="fixed z-10 text-nowrap rounded border border-theme-blue-500 bg-white px-1 text-xs font-semibold text-theme-blue-500"
        >
            {{ text }}
        </div>
        <span v-if="visible" :style="outerTriangleStyle" class="fixed">
            <span :style="innerTriangleStyle"></span>
        </span>
    </transition-group>
</template>
