<script setup lang="ts">
import getClassesFromColor from '@/domains/common/services/getBaseButtonColor';
import ButtonColorEnum from '@/domains/common/typescript/ButtonColorEnum';
import { computed } from 'vue';

interface Props {
    id: string;
    icon?: Object;
    label: string;
    showLabel?: boolean;
    disabled?: boolean;
    defaultClasses?: boolean;
    color?: ButtonColorEnum;
    accept?: string;
}

const props = withDefaults(defineProps<Props>(), {
    icon: undefined,
    showLabel: true,
    disabled: false,
    defaultClasses: true,
    color: ButtonColorEnum.None,
    accept: '*',
});

const emit = defineEmits(['input']);

const colorClasses = computed(() => getClassesFromColor(props.color, props.disabled));

function getFile(event: Event) {
    const target = event.target as HTMLInputElement;
    const file = target.files?.item(0);

    if (file) {
        emit('input', file);
    }
}
</script>

<template>
    <div>
        <label
            :for="props.id"
            :class="[
                defaultClasses && 'flex cursor-pointer items-center justify-center rounded-2xl px-5 py-2 font-semibold',
                $attrs.class,
                colorClasses.background,
                colorClasses.text,
            ]"
        >
            <span>
                {{ props.label }}
            </span>
            <component :is="props.icon" v-if="props.icon" />
        </label>
        <input :id="props.id" type="file" class="hidden" :accept="accept" @input="getFile" />
    </div>
</template>
